import { FormattedMessage, useIntl } from 'react-intl'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'

import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import ListProduct from 'src/components/ListProduct/ListProduct'
import ProductsContainer from 'src/components/ProductsContainer/ProductsContainer'
import Thumb from 'src/components/UI/Carousel/Thumb'
import useStore from 'src/stores/useStore'
import { Flex, Box } from 'src/components/UI/Grid/Grid'
import { useCentraProducts } from 'src/hooks/useCentraProducts'
import {
  ProductListArrowLeft,
  ProductListArrowRight,
} from 'src/components/UI/Icons/Icons'

import styles from './ModuleProductList.module.scss'

import type { AinoProduct, Maybe } from 'src/apollo/types'

const ModuleProductList = ({ title, products, link, isSkinType }: any) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [pages, setPages] = useState(0)
  const router = useRouter()
  const intl = useIntl()
  const locale = router.locale
  const { cart } = useStore()
  const { centraProducts } = useCentraProducts({ products, cart, locale })
  const [finger, setFinger]: any = useState(null)

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 37) {
      // Left
      pageLeft()
    } else if (e.keyCode === 39) {
      // Right
      pageRight()
    }
  }

  useEffect(() => {
    setCurrentPage(0)
    if (products && products.length > 0) {
      setPages(products.length)
    }
  }, [locale, products])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  const pageLeft = () => {
    if (finger && currentPage > 0) {
      finger.animateToPage(currentPage - 1)
    }
  }

  const pageRight = () => {
    if (finger && currentPage + 3 < pages) {
      finger.animateToPage(currentPage + 1)
    }
  }

  const onPage = (page: number) => {
    setCurrentPage(page)
  }

  return (
    <ProductsContainer
      products={products}
      listOnly={true}
      renderResult={({ products }) => (
        <Flex
          paddingTop={[10, null, null, 12]}
          paddingBottom={[10, null, null, 14]}
          flexDirection="column"
          width={1}
          className={styles.hoverWrapper}
          as="section"
        >
          <Flex
            marginBottom={[8, null, null, 9]}
            // paddingX={[5, null, null, 14]}
            className={styles.titleWrapper}
            justifyContent="space-between"
          >
            <Box className={styles.title} as="h2">
              {title}
            </Box>
            {products && products.length > 3 && (
              <Flex
                className={styles.nav}
                // alignItems="flex-start"
                justifyContent="space-between"
                paddingTop={'1px'}
              >
                <Box
                  onClick={pageLeft}
                  className={
                    currentPage < 1
                      ? [styles.button, styles.disabled].join(' ')
                      : styles.button
                  }
                  as="button"
                  aria-label={intl.formatMessage({
                    defaultMessage: 'Previous',
                    id: 'X4RT4P',
                    description: 'Accessible text for icon button',
                  })}
                >
                  <ProductListArrowLeft />
                </Box>
                <Box
                  onClick={pageRight}
                  marginLeft={8}
                  className={
                    currentPage + 3 < pages
                      ? styles.button
                      : [styles.button, styles.disabled].join(' ')
                  }
                  as="button"
                  aria-label={intl.formatMessage({
                    defaultMessage: 'Next',
                    id: 'xlgc5L',
                    description: 'Accessible text for icon button',
                  })}
                >
                  <ProductListArrowRight />
                </Box>
              </Flex>
            )}
            {link && link.slug && (
              <Flex className={styles.link} alignItems="center">
                <LinkComponent href={link.slug}>
                  <FormattedMessage
                    defaultMessage="View all"
                    id="29fzwE"
                    description="View all button on carousel"
                  />
                </LinkComponent>
              </Flex>
            )}
          </Flex>
          <Thumb
            pages={products ? products.length : 0}
            className={styles.container}
            onPage={onPage}
            onApi={(api: any) => {
              setFinger(api)
            }}
            perPage={0.32}
            repeat={false}
            containerPadding={44} // Must be the same as the container padding in the css
            currentPage={currentPage}
          >
            <div className={styles.slides}>
              {products &&
                products.map((product: Maybe<AinoProduct>, index: number) => {
                  const centraProduct: any =
                    centraProducts &&
                    centraProducts.find(
                      (centraProduct) => centraProduct.id === product?.id
                    )
                  return product ? (
                    <div key={index}>
                      <Box paddingX={4}>
                        <ListProduct
                          product={product}
                          centraProduct={centraProduct}
                          key={index}
                        />
                      </Box>
                    </div>
                  ) : null
                })}
            </div>
          </Thumb>
          <Flex className={styles.mobileContainer} paddingX={'10px'}>
            {products &&
              products.length > 0 &&
              products.map((product: Maybe<AinoProduct>, index: number) => {
                const centraProduct: any = centraProducts.find(
                  (centraProduct) => centraProduct.id === product?.id
                )
                return product ? (
                  <Box
                    paddingX={[isSkinType ? '10px' : 2, null, null, 3]}
                    width={[5 / 6, null, 4 / 10]}
                    key={index}
                  >
                    <ListProduct
                      product={product}
                      centraProduct={centraProduct}
                    />
                  </Box>
                ) : null
              })}
          </Flex>
        </Flex>
      )}
    />
  )
}

export default ModuleProductList
