import { FormattedMessage } from 'react-intl'

import BlogListItem from 'src/components/BlogListItem/BlogListItem'
import { Flex, Box } from 'src/components/UI/Grid/Grid'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'

import styles from './ModuleArticlesListing.module.scss'
import { Props } from './types'

const ModuleArticlesListing = ({
  title,
  theme,
  articles,
  background = '#ffffff',
  isPDP,
}: Props) => {
  const mainArticle = articles ? articles[0] : null
  const wrapperClass = [styles.wrapper]
  switch (theme) {
    case 'green':
      wrapperClass.push(styles.green)
      break
    default:
      break
  }
  return mainArticle ? (
    <Box
      as="section"
      className={wrapperClass.join(' ')}
      bg={background}
      aria-label={title || undefined}
      paddingTop={[10, null, null, 12]}
      paddingBottom={[7, null, null, 14]}
      paddingX={isPDP ? [0, null, null, 24] : [0, null, null, 14]}
    >
      <Flex flexDirection="column">
        <Flex
          paddingX={[5, null, null, 0]}
          marginBottom={[9]}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box className={styles.title} as="h3">
            {title}
          </Box>
          <Box paddingTop={[0, null, null, '2px']}>
            <LinkComponent className={styles.link} href="/inspiration">
              <FormattedMessage
                defaultMessage="View all"
                id="7VYhK7"
                description="Text for view all blog posts button"
              />
            </LinkComponent>
          </Box>
        </Flex>
        <Flex
          flexDirection={['column', null, null, 'row']}
          justifyContent="space-between"
        >
          <Flex
            flexGrow={0}
            flexDirection="column"
            width={[1, null, null, 4.5 / 8]}
            paddingX={[5, null, null, 0]}
            marginBottom={[12, null, null, 0]}
            className="hideMobile"
          >
            <div className={styles.leftSection}>
              <BlogListItem post={mainArticle} span={1} />
            </div>
          </Flex>
          <Flex
            flexGrow={0}
            paddingRight={['10px', null, null, 0]}
            width={[1, null, null, 3.5 / 8]}
            paddingLeft={['10px', null, null, 8]}
            flexDirection={['row', null, null, 'column']}
            className={styles.rightSection}
          >
            <Box
              paddingX={[2, null, null, 0]}
              width={[5 / 6, null, 4 / 10, 1]}
              flexShrink={0}
              className="hideDesktop"
              marginBottom={[0, null, null, 16]}
            >
              <BlogListItem post={mainArticle} span={1} />
            </Box>
            {articles &&
              articles.map((article: any, index: number) =>
                index === 0 ? null : (
                  <Box
                    paddingX={[2, null, null, 0]}
                    width={[5 / 6, null, 4 / 10, 1]}
                    flexShrink={0}
                    marginBottom={[
                      0,
                      null,
                      null,
                      index === articles.length - 1 ? 0 : 12,
                    ]}
                    key={index}
                  >
                    <BlogListItem
                      key={article.id}
                      small
                      post={article}
                      span={1}
                    />
                  </Box>
                )
              )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  ) : null
}

export default ModuleArticlesListing
