import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'

import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import { Box, Flex } from 'src/components/UI/Grid/Grid'
import { getContentfulSrcSet } from 'src/utils/image'

import styles from './BlogListItem.module.scss'

import type { ContentfulBlogContent, Maybe } from 'src/apollo/types'

const BlogListItem = ({
  post,
  span,
  small,
  hideTags,
}: {
  post: ContentfulBlogContent | any
  span: number
  small?: boolean
  hideTags?: boolean
}) => {
  const { image, labels, slug, title, date } = post
  const router = useRouter()
  const intl = useIntl()

  const formattedDate = date ? new Date(date) : null
  return (
    <LinkComponent
      href={`/emmas-guide/${slug}`}
      onClick={() =>
        router.asPath.includes('emmas-guide') && window.scrollTo(0, 0)
      }
      className={[styles.wrapper, small ? styles.small : ''].join(' ')}
    >
      <Box
        marginBottom={[5, null, null, 6]}
        width={1}
        className={styles.imageWrapper}
        paddingTop={
          small
            ? ['100%', null, null, '75%']
            : span === 1
            ? ['100%', null, null, '75%']
            : span === 2
            ? ['100%', null, null, '87.5%']
            : ['100%', null, null, '56.25%']
        }
      >
        {image && image.url && (
          <Image
            srcSet={getContentfulSrcSet(image.url)}
            alt={image.title ? image.title : ''}
          />
        )}
      </Box>
      <Flex flexDirection="column" width={1} className={styles.content}>
        {/* {formattedDate && (
          <Box
            paddingLeft={'1px'}
            marginBottom={3}
            as="span"
            className={styles.date}
          >
            {formattedDate.toLocaleString(undefined, {
              day: '2-digit',
              year: 'numeric',
              month: 'short',
            })}
          </Box>
        )} */}
        <Box
          aria-label={intl.formatMessage({
            defaultMessage: 'Categories',
            id: 'khDVBz',
            description: 'Accessible text for blog categories',
          })}
          marginBottom={[3, null, null, 4]}
        >
          {labels &&
            labels.map(
              (label: Maybe<string>, i: number) =>
                i === 0 &&
                (hideTags ? (
                  <span
                    key={label}
                    className={[styles.label, styles.noHover].join(' ')}
                  >
                    {label}
                  </span>
                ) : (
                  <LinkComponent
                    href={`/emmas-guide#cat=${label}`}
                    key={label}
                    className={styles.label}
                  >
                    {label}
                  </LinkComponent>
                ))
            )}
        </Box>
        <Box
          paddingLeft="1px"
          marginBottom={3}
          className={styles.title}
          as="span"
        >
          {title}
        </Box>
      </Flex>
    </LinkComponent>
  )
}

export default BlogListItem
